<template>
  <div>
      <div class="grid md:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 flex gap-2 mb-5">
        <div class="">
          <vs-input
            id="protocol_number"
            class="w-full"
            :label="$t('appeal.protocol')"
            v-model="model.protocol_number"
            :readonly="true"/>
        </div>
        <div class="">
          <vs-input
            id="created_at"
            class="w-full"
            :label="$t('appeal.created_at')"
            v-model="model.created_dt_fmt"
            :readonly="true"/>
        </div>
        <div class="">
          <vs-input
            id="status"
            :label="$t('appeal.status')"
            class="text-warning w-full"
            v-model="model.handling_status.label"
            :readonly="true"/>
        </div>
        <div class="content-end">
          <div>
            <form-handling-history-popup :appeal_id="model.id"
                                         :appeal_handling_id="appeal_handling_id"
                                         :visible.sync="popupHistoryView"

                                         @close="showHanlingHistoryPopup"></form-handling-history-popup>
            <vs-button
              class="float-base"
              color="primary"
              type="border"
              @click="popupHistoryView = true">{{ $t('appeal.handling.history') }}</vs-button>
            </div>
        </div>
      </div>

      <div class="w-full">
        <vs-input
            id="model_name"
            class="w-full"
            :label="$t('Questionnaire')"
            v-model="model.model_name"
            :readonly="true"/>
      </div>

      <div class="vx-row mb-6 mt-6">
        <div class="vx-col w-full">
          <label class="text-sm pl-1">{{ $t('appeal.reason') }}</label>
          <div v-html-safe="model.reason"
            id="reason"
            class="editor_data editor-content ck-content"></div>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="text-sm pl-1">{{ $t('appeal.reasoning') }}</label>
          <div v-html-safe="model.reasoning"
            id="reason"
            class="editor_data editor-content ck-content"></div>
        </div>
      </div>
  </div>
</template>

<script>
  import FormHandlingHistoryPopup from './handling/FormHandlingHistoryPopup.vue'

export default {
  directives: {

  },
  components: {
    FormHandlingHistoryPopup,
  },
  props: {
    id: {
      default: null,
      type: Number,
    },
    appeal_handling_id: {
      default: null,
      type: Number,
    },
    model:{
      default: null,
      required: true,
      type: Object
    }
  },

  data: () => ({
    popupHistoryView: false,
    serviceHandling: null
  }),
  computed: {

  },
  beforeMount() {

  },
  mounted() {

  },

  methods: {
    showHanlingHistoryPopup(show){
      this.popupHistoryView = show
    }
  }
}
</script>

<style>

</style>
