<template>
  <div>
    <vx-card class="mb-4">
      <form-detail-include :model="model.appeal" :appeal_handling_id="id"></form-detail-include>
      <div class="w-full pt-5">
        <vs-divider>{{ $t('appeal.handling.label') }}</vs-divider>
      </div>
      <div class="vx-row mb-6 mt-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">{{ $t('appeal.handling.public_access') }}</label>
          <div>
            <vs-radio v-model="public_access" vs-name="public_access" :vs-value="false" class="mr-4">{{ $t('appeal.handling.private') }}</vs-radio>
            <vs-radio v-model="public_access" vs-name="public_access" :vs-value="true" class="mr-4">{{ $t('appeal.handling.public') }}</vs-radio>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6 mt-2">
        <div class="vx-col w-full">
          <complex-editor v-bind:editor_data.sync="description"/>
        </div>
      </div>

      <div class="w-full flex gap-2 justify-end print:hidden">
        <vs-button
            v-permission="'appeal_handlings.edit'"
            class="btn-primary mr-2"
            @click="updateHandling('IH')">{{ $t('action.save') }}</vs-button>
        <vs-button
            v-permission="'appeal_handlings.edit'"
            class="btn-primary mr-2"
            color="warning"
            @click="updateHandling('PE')">{{ $t('action.pending') }}</vs-button>
        <vs-button
            v-permission="'appeal_handlings.deferred'"
            class=" mr-2"
            color="success"
            @click="updateHandling('DE')">{{ $t('action.deferred') }}</vs-button>
        <vs-button
            v-permission="'appeal_handlings.reject'"
            class="float-right mr-2"
            color="danger"
            @click="updateHandling('RE')">{{ $t('action.reject') }}</vs-button>
          <vs-button
            class=""
            color="primary"
            type="border"
            @click="goback">{{ $t('action.back') }}</vs-button>
      </div>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import ComplexEditor from '../../questionnaires/elements/ComplexEditor.vue'

import FormDetailInclude from '../FormDetailInclude.vue'

import AppealHandlingService from '@/services/api/AppealHandlingService'


export default {
  directives: {

  },
  components: {
    FormDetailInclude,
    ComplexEditor
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    description: '',
    public_access: false,
    model: {
      id: null,
    }
  }),
  computed: {

  },
  beforeMount() {
    this.service = AppealHandlingService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },
  methods: {
    loadData(id) {
      this.$vs.loading()

      this.service.read(id).then(
        response => {
          this.model = response
        },
        error => {this.$vs.loading.close()}
      ).finally(() => {this.$vs.loading.close()})
    },
    updateHandling(status) {
      this.$vs.loading()

      const modelData = {
        id: this.model.id,
        status: status,
        public_access: this.public_access,
        description: this.description,
      }

      this.service.updateHandling(this.id, modelData).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.description = ''
          this.loadData(this.id)
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs,this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(() => { this.$vs.loading.close() })
    },
    goback() {
      this.$router.push('/appeal_handlings')
    },
    isAdmin() {
      return isAdmin()
    },
  }
}
</script>

<style>

</style>

