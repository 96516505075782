var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c("form-detail-include", {
            attrs: { model: _vm.model.appeal, appeal_handling_id: _vm.id },
          }),
          _c(
            "div",
            { staticClass: "w-full pt-5" },
            [
              _c("vs-divider", [
                _vm._v(_vm._s(_vm.$t("appeal.handling.label"))),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "vx-row mb-6 mt-6" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(_vm._s(_vm.$t("appeal.handling.public_access"))),
              ]),
              _c(
                "div",
                [
                  _c(
                    "vs-radio",
                    {
                      staticClass: "mr-4",
                      attrs: { "vs-name": "public_access", "vs-value": false },
                      model: {
                        value: _vm.public_access,
                        callback: function ($$v) {
                          _vm.public_access = $$v
                        },
                        expression: "public_access",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("appeal.handling.private")))]
                  ),
                  _c(
                    "vs-radio",
                    {
                      staticClass: "mr-4",
                      attrs: { "vs-name": "public_access", "vs-value": true },
                      model: {
                        value: _vm.public_access,
                        callback: function ($$v) {
                          _vm.public_access = $$v
                        },
                        expression: "public_access",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("appeal.handling.public")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "vx-row mb-6 mt-2" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("complex-editor", {
                  attrs: { editor_data: _vm.description },
                  on: {
                    "update:editor_data": function ($event) {
                      _vm.description = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "w-full flex gap-2 justify-end print:hidden" },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "appeal_handlings.edit",
                      expression: "'appeal_handlings.edit'",
                    },
                  ],
                  staticClass: "btn-primary mr-2",
                  on: {
                    click: function ($event) {
                      return _vm.updateHandling("IH")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("action.save")))]
              ),
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "appeal_handlings.edit",
                      expression: "'appeal_handlings.edit'",
                    },
                  ],
                  staticClass: "btn-primary mr-2",
                  attrs: { color: "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.updateHandling("PE")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("action.pending")))]
              ),
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "appeal_handlings.deferred",
                      expression: "'appeal_handlings.deferred'",
                    },
                  ],
                  staticClass: " mr-2",
                  attrs: { color: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.updateHandling("DE")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("action.deferred")))]
              ),
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "appeal_handlings.reject",
                      expression: "'appeal_handlings.reject'",
                    },
                  ],
                  staticClass: "float-right mr-2",
                  attrs: { color: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.updateHandling("RE")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("action.reject")))]
              ),
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "border" },
                  on: { click: _vm.goback },
                },
                [_vm._v(_vm._s(_vm.$t("action.back")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }