var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "grid md:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 flex gap-2 mb-5",
      },
      [
        _c(
          "div",
          {},
          [
            _c("vs-input", {
              staticClass: "w-full",
              attrs: {
                id: "protocol_number",
                label: _vm.$t("appeal.protocol"),
                readonly: true,
              },
              model: {
                value: _vm.model.protocol_number,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "protocol_number", $$v)
                },
                expression: "model.protocol_number",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {},
          [
            _c("vs-input", {
              staticClass: "w-full",
              attrs: {
                id: "created_at",
                label: _vm.$t("appeal.created_at"),
                readonly: true,
              },
              model: {
                value: _vm.model.created_dt_fmt,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "created_dt_fmt", $$v)
                },
                expression: "model.created_dt_fmt",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {},
          [
            _c("vs-input", {
              staticClass: "text-warning w-full",
              attrs: {
                id: "status",
                label: _vm.$t("appeal.status"),
                readonly: true,
              },
              model: {
                value: _vm.model.handling_status.label,
                callback: function ($$v) {
                  _vm.$set(_vm.model.handling_status, "label", $$v)
                },
                expression: "model.handling_status.label",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "content-end" }, [
          _c(
            "div",
            [
              _c("form-handling-history-popup", {
                attrs: {
                  appeal_id: _vm.model.id,
                  appeal_handling_id: _vm.appeal_handling_id,
                  visible: _vm.popupHistoryView,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.popupHistoryView = $event
                  },
                  close: _vm.showHanlingHistoryPopup,
                },
              }),
              _c(
                "vs-button",
                {
                  staticClass: "float-base",
                  attrs: { color: "primary", type: "border" },
                  on: {
                    click: function ($event) {
                      _vm.popupHistoryView = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("appeal.handling.history")))]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "w-full" },
      [
        _c("vs-input", {
          staticClass: "w-full",
          attrs: {
            id: "model_name",
            label: _vm.$t("Questionnaire"),
            readonly: true,
          },
          model: {
            value: _vm.model.model_name,
            callback: function ($$v) {
              _vm.$set(_vm.model, "model_name", $$v)
            },
            expression: "model.model_name",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "vx-row mb-6 mt-6" }, [
      _c("div", { staticClass: "vx-col w-full" }, [
        _c("label", { staticClass: "text-sm pl-1" }, [
          _vm._v(_vm._s(_vm.$t("appeal.reason"))),
        ]),
        _c("div", {
          directives: [
            {
              name: "html-safe",
              rawName: "v-html-safe",
              value: _vm.model.reason,
              expression: "model.reason",
            },
          ],
          staticClass: "editor_data editor-content ck-content",
          attrs: { id: "reason" },
        }),
      ]),
    ]),
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c("div", { staticClass: "vx-col w-full" }, [
        _c("label", { staticClass: "text-sm pl-1" }, [
          _vm._v(_vm._s(_vm.$t("appeal.reasoning"))),
        ]),
        _c("div", {
          directives: [
            {
              name: "html-safe",
              rawName: "v-html-safe",
              value: _vm.model.reasoning,
              expression: "model.reasoning",
            },
          ],
          staticClass: "editor_data editor-content ck-content",
          attrs: { id: "reason" },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }