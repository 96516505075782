import { render, staticRenderFns } from "./FormDetailInclude.vue?vue&type=template&id=4e5d5566&"
import script from "./FormDetailInclude.vue?vue&type=script&lang=js&"
export * from "./FormDetailInclude.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/user/workspace/educatena/educatena-app-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4e5d5566')) {
      api.createRecord('4e5d5566', component.options)
    } else {
      api.reload('4e5d5566', component.options)
    }
    module.hot.accept("./FormDetailInclude.vue?vue&type=template&id=4e5d5566&", function () {
      api.rerender('4e5d5566', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/appeal/FormDetailInclude.vue"
export default component.exports